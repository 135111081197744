.lightbox-container {
  position: relative;

  @include phone-small {
    padding: 50px 0;
  }
}

.lightbox {
  display: none;
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  background:rgba(0,0,0,0.4);
  -webkit-backdrop-filter: blur(10px);

  img {
    max-width: 96%;
    max-height: 96%;
    margin-top: 2%;

    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .close-button {
    background: url('/assets/images/shelf-close-button.svg');
    background-size: 36px;
    background-repeat: no-repeat;
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    right: 32px;
    top: 32px;
    z-index: 998;

    &:hover {
      opacity: 0.6;
    }
  }

  &:target {
    outline: none;
    display: block;
    -webkit-animation: fadeAnimation 0.2s;
    -moz-animation: fadeAnimation 0.2s;
    -o-animation: fadeAnimation 0.2s;
    animation:fadeAnimation 0.2s;
  }
}

@keyframes fadeAnimation {
    0% {
        opacity:0
    }

    100% {
        opacity:1
    }
}

@-o-keyframes fadeAnimation {
    0% {
        opacity:0
    }

    100% {
        opacity:1
    }
}

@-moz-keyframes fadeAnimation {
    0% {
        opacity:0
    }

    100% {
        opacity:1
    }
}

@-webkit-keyframes fadeAnimation {
    0% {
        opacity:0
    }

    100% {
        opacity:1
    }
}