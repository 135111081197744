.card {
	position: relative;
	display: block;
	width: 100%;
	background: #FFFFFF;
	border-radius: $card-border-radius;
//	margin-bottom: $container-column-padding;
//
//	&:last-child {
//		margin-bottom: 0;
//	}
}

.card-break {
	display: block;
	width: 100%;
	height: $container-column-padding
}

.card .content {
	padding: 60px;
}

.card .heading {
	padding: 0;
	margin: 0 0 0.7em 0;
	font-weight: 800;
	font-size: 34px;
	color: #BD2457;
	letter-spacing: -0.02em;
	line-height: 1.3em;
	
	&.small {
		font-size: 26px;
	}
}

.card .body {
	font-weight: 600;
	font-size: 20px;
	color: #3A3A3A;
	letter-spacing: -0.01em;
	line-height: 1.5em;
	
	&.small {
		font-size: 18px;
	}
	
	margin-bottom: 30px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.card .separator {
	background-color: #F5F5F7;
	height: 1px;
	margin: 50px auto 50px auto;
}

.card-grid-container {
	width: 100%;
	
	&.two-main-leading {
		display: grid;
		grid-template-columns: 60% auto;
		column-gap: 100px;
		align-items: center;
	}
	
	&.two-main-trailing {
		display: grid;
		grid-template-columns: auto 60%;
		column-gap: 100px;
		align-items: center;
	}
}

.card-block {
	display: block;
	background: $card-background-color;
}

/*
	Responsive
*/

@include media("<=large") {
	.card {
		width: unset;
		margin: 0 32px;
	}
	
	.card .content {
		padding: 40px;
	}
	
	.card-grid-container {
		&.two-main-leading {
			grid-template-columns: 55% auto;
			column-gap: 60px;
		}
		
		&.two-main-trailing {
			grid-template-columns: auto 55%;
			column-gap: 60px;
		}
	}
}

/// Phones
@include media("<medium") {
	.card {
		width: unset;
		margin: 0 16px;
		border-radius: $card-border-radius-small;
	}
	
	.card .content {
		padding: 30px;
	}
	
	.card .heading {
		font-size: 24px;
	}
	
	.card .separator {
		margin: 50px auto 38px auto;
	}
	
	.card .body {
		font-size: 16px;
	}
	
	.card-grid-container {
		&.two-main-leading {
			display: block;
		}
		
		&.two-main-trailing {
			display: block;
		}
	}
}