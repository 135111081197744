$main-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
$body-background-color: #F5F5F7;

$body-text-color: #3A3A3A;
$card-background-color: #FFFFFF;

$container-width: 960px;
$container-column-width: 60px;
$container-column-padding: 30px;

$card-border-radius: 33px;
$card-border-radius-small:  26px;