.list-container {
  max-width: 720px;
  margin: 0 auto;

  @include media("<=medium") {

    &.two {
      padding: 24px !important;
    }

    &.one {
      padding: 24px !important;
    }
  }

  &.two {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    column-gap: 52px;
    row-gap: 32px;
    align-items: top;
  }

  &.single {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }
}

.list-element {
  display: block;
  width: 100%;
}

@include media("<large") {
  .list-container.two {
    grid-template-columns: 1fr 1fr;
    padding: 0 34px;
  }

  .list-container.faq {
    grid-template-columns: 1fr;
    padding: 0 34px;
  }
}

@include media("<small") {
  .list-container {
    margin: 44px 24px 44px 24px;
  }

  .list-container.faq {
    grid-template-columns: 1fr;
    padding: 0 14px;
  }

  .list-container.two {
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    row-gap: 12px;
    padding: 14px;
  }
}
