article {
  padding: 4em 8em;

  @include media("<=large") {
    padding: 24px;
  }

  @include media("<=phone") {
    padding: 18px;
  }
}

article h1.intro {
  font-weight: 800;
  font-size: 2.7em;
  color: #bd2457;
  line-height: 1.3em;
  margin-bottom: 1em;

  @include media("<=medium") {
    font-size: 2em;
    margin-bottom: 0.8em;
  }

  @include media("<=phone") {
    font-size: 1.7em;
    margin-bottom: 0.5em;
  }
}

article p.intro {
  font-weight: 500;
  font-size: 22px;
  color: #3a3a3a;
  letter-spacing: -0.02em;
  line-height: 1.5em;

  @include media("<=medium") {
    font-size: 19x;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

span.intro-metadata {
  display: block;
  max-width: 600px;
  margin: 0 auto 7px auto;
  color: #888;
  font-size: 14px;
}

article h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
  line-height: 1.2em;
  margin-bottom: 0.2em;

  max-width: 600px;
  margin: 0 auto;
}

article h1 {
  @extend .body-l;
  margin-bottom: 16px;

  @include media("<=medium") {
    font-size: 1.9em;
  }
}

article h2 {
  @extend .body-l;
  margin-bottom: 16px;

  @include media("<=medium") {
    font-size: 1.8em;
  }
}

article h3 {
  @extend .body-l;
  font-size: 24px;
  margin-bottom: 16px;
  line-height: 1.4em;

  @include media("<=medium") {
    font-size: 20px;
  }
}

article h4 {
  @extend .body-l;
  font-size: 18px;
  margin-bottom: 16px;
  line-height: 1.3em;

  @include media("<=medium") {
    font-size: 16px;
  }
}

article h5 {
  @extend .body-l;
  font-size: 15px;
  margin-bottom: 16px;
  line-height: 1.2em;

  @include media("<=medium") {
    font-size: 14px;
  }
}

article h6 {
  @extend .body-l;
  font-size: 15px;
  margin-bottom: 16px;
  line-height: 1.2em;

  @include media("<=medium") {
    font-size: 13px;
  }
}

article p {
  @extend .body-s;
  max-width: 600px;
  margin: 0 auto 24px auto;
  line-height: 1.5em;

  &.muted {
    color: #555;
  }
}

article ul,
article ol {
  max-width: 600px;
  margin: 1.3em auto 1.3em auto;
  margin-top: 1.3em;
  padding-left: 1.5em;
}

article ul li,
article ol li {
  @extend .body-s;
  background-color: #fff;
}

article ol {
  margin-top: 1.3em;
  padding-left: 1.5em;
}

article .separator {
  background-color: #f5f5f7;
  height: 1px;
  margin: 50px auto 50px auto;
  max-width: 600px;

  &.small {
    margin: 20px auto 20px auto;
  }
}

article img {
  max-width: 100%;

  border-radius: 8px;
}

article img.rounded {
  border-radius: 12px;
  margin-bottom: 1.5em;

  width: 100%;
  box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.25);
}

article pre {
  padding: 12px;
  border-radius: 12px;
}

span.note {
  display: block;
  padding: 16px;
  background-color: #edf5ff;
  border-radius: 8px;
  border: 1px solid #cde5ff;

  &.gray {
    background: #e4e4e4;
    border: none;
  }
}

article img + em {
  font-size: 12px;
  color: #333;
}

article {
  .button-container {
    margin: 40px auto;
    display: block;
    text-align: center;

    a {
      display: inline-block;
      margin: 0 auto;
      padding: 12px 18px;
      border-radius: 100px;
      background: #156cd0;

      color: white;
      font-size: 18px;
      font-weight: 700;

      &:hover {
        background: lighten(#156cd0, 10%);
      }
    }
  }
}
