.lead {
	display: block;
	margin: 0 auto 100px auto;
	width: 80%;

	&.block {
		width: 100%;
	}

	&.no-margin {
		margin-bottom: 0;
	}

  .video-container {
    margin-bottom: 2em;
  }
}

.lead h3 {
	font-weight: 800;
	font-size: 40px;
	color: #BD2457;
	letter-spacing: -0.05em;
	line-height: 1.5em;
	margin-bottom: 26px;
}

.lead p {
	font-weight: 500;
	font-size: 22px;
	color: #3A3A3A;
	letter-spacing: -0.02em;
	line-height: 1.5em;
	margin: 0 0 1.7em 0;

	&:last-child {
		margin-bottom: 0;
	}
}

@include media("<=medium") {
	.lead {
		margin-bottom: 70px;
	}
	
	.lead h3 {
		font-size: 30px;
		margin-bottom: 18px;
	}
	
	.lead p {
		font-size: 20px;
	}
}

@include media("<=small") {
	.lead {
		margin-bottom: 50px;
	}
	
	.lead h3 {
		font-size: 26px;
		margin-bottom: 14px;
	}
	
	.lead p {
		font-size: 20px;
	}
}
