$phone-width: 375px;
$tablet-width: 768px;
$desktop-width: 1024px;
$desktop-large-width: 1124px;

@mixin phone-small {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop-large {
@media (min-width: #{$desktop-large-width}) {
    @content;
  }	
}