.tiles-container {
  max-width: 720px;
  margin: 0 auto 16px auto;
  
  &.two {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 16px;
    align-items: top;
  }
  
  &.single {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 16px;
  }
}

.tile {
  display: block;
  width: 100%;
  background: white;
  border-radius: 18px;
  position: relative;

  .separator {
    margin: 12px auto;
    width: 100%;
    height: 1px;
    background-color: #eee;
  }
  
  .tile-body {
    padding: 34px;
    position: relative;

    &.tile-body-bottom {
      padding-top: 0;
    }
    
    h5 {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      margin-bottom: 7px;
    }
    
    p {
      letter-spacing: 0;
    }
  }
  
  .tile-footer {
    display: block;
    width: 100%;
    
    &.tile-footer-push {
      position: absolute;
      bottom: 0;
    }
    
    &.tile-footer-padding {
      padding: 0 34px 34px 34px;
    }
    
    img {
      display: block;
      max-width: 100%;
      
      &.rounded {
        border-radius: 8px;
      }
    }
  }
  
  &.dark {
    background: #1A1A1A;
  }
}

/* Custom tiles */

.tile-action-button {
  background-image: url('/assets/images/shelf-action-button.jpg') !important;
  background-size: 403px 289px !important;
  background-position: bottom left !important;
  background-repeat: no-repeat !important;
  min-height: 300px;


  @include media("<small") {
    background-size: 80% !important;
    min-height: 400px;
  }

  @include media("<medium") {
    min-height: 440px;
  }
}

.tile-shelf {
  @extend .dark;

  background-image: url('/assets/images/shelf-ui-highlighted.png') !important;
  background-size: 490px 399px !important;
  background-position: top right !important;
  background-repeat: no-repeat !important;
  min-height: 300px;

  .tile-body {
    max-width: 40%;
  }

  @include media("<medium") {
    background-position: right bottom !important;
    background-size: 50% !important;

    .tile-body {
      max-width: 70%;
    }
  }
}

.tile-shelf-actions {
  .tile-body {
    &:first-child {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 12px;
      column-gap: 40px;
    }

    img {
      max-width: 100%;
      border-radius: 8px;
    }
  }

  .file-ops-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 14px;
    column-gap: 14px;
    width: 100%;

    ul li {
      font-size: 14px;
      color: #444;
      padding: 0 2px;
    }
  }
}

.tile-dropover-cloud {
  background: #2F18FF;
  position: relative;
  min-height: 400px;
  
  .tile-body {
    position: relative;
    
    h5 {
      position: relative;
      color: white;
      top: -6px;

      @include media("<medium") {
        position: relative;
        left: 26px;
      }
    }
  }

  img.dropover-cloud-icon {
    position: absolute;
    top: 26px;
    left: 24px;

    width: 24px;
    height: 23px;
  }

  span { 
    color: white;
    text-align: center;
    padding: 0 34px;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  a.more-button {
    position: absolute;
    bottom: 24px;
    right: 24px;
  }
}  

@include media("<medium") {
  .tiles-container {
    &.two {
      grid-template-columns: 1fr;
      margin: 14px;
    }

    &.single {
      margin: 14px;
      max-width: 100%;
    }
  }

  .tile {
    .tile-footer.tile-footer-push {
      position: unset;
      bottom: unset;
    }
  }

  .tile-action-button {
    min-height: 400px;
  }

  .tile-shelf {
  }
}
