.footer-section-container {
  padding-top: 50px;
  border-top: 1px solid #eee;
  background: white;
}

footer {
  font-size: 13px;
  color: #a3a3a3;
}

footer .footer-content-container {
  padding-left: 24px;
  padding-right: 24px;
  display: grid;
  grid-template-columns: 40% auto;

  @media (max-width: 600px) {
    // For small screens, switch to a single column
    grid-template-columns: 1fr;
    // You can also reset grid-template-rows or rely on auto-row sizing
    grid-auto-rows: auto;
  }
}

footer .footer-app {
  display: grid;
  grid-template: "icon label";
  grid-template-columns: 47px auto;
  column-gap: 18px;

  @media (max-width: 600px) {
    margin-bottom: 24px;
  }

  a {
    img {
      // Smooth transition for both opacity and scale
      transition:
        opacity 0.15s ease-in-out,
        transform 0.15s ease-in-out;
    }

    &:hover {
      img {
        opacity: 0.9;
        transform: scale(0.95);
      }
    }
  }

  .icon {
    img {
      position: relative;
      top: -4px;
    }
  }

  .label {
    span {
      &.title {
        display: block;
        font-weight: 500;
        color: black;
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
  }
}

/* Sitemap */

footer .footer-sitemap-container {
  display: block;

  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 100px;
  align-items: top;
  justify-content: end;

  @media (max-width: 800px) {
    column-gap: 50px;
  }

  // Ensure the columns use 100% available width
  @media (max-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: normal;
    justify-content: center;
  }
}

.footer-sitemap-col {
  ul {
    padding: 0;
  }

  li {
    list-style-type: none;
    margin-bottom: 10px;

    &.title {
      font-weight: 500;
      color: black;
      font-size: 14px;
      margin-bottom: 14px;
    }

    a {
      font-weight: 400;
      color: #666;
      font-size: 14px;

      &:hover {
        color: black;
      }
    }
  }
}

// Copyright
footer .footer-copyright {
  display: block;
  padding-top: 100px;
  padding-bottom: 24px;
  margin: 0 auto;
  text-align: center;

  font-family: Menlo, Consolas, monospace;
  font-size: 11px;
  font-weight: 400;
  color: #6d6d6d;

  a {
    font-weight: 500;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    font-family: "SF Mono", Menlo, Consolas, monospace;
  }
}
