.fold-container {
	background: #163D66;
	background-image: url('/assets/images/fold-background-monterey-light.jpg');
	background-size: cover;
	min-height: 100vh;
}

.fold-container .app-icon {
	display: block;
	text-align: center;
	margin: 90px auto 0 auto;
	
	img {
		display: block;
		margin: 0 auto;
		width: 231px;
		height: 220px;
	}
	
	h1 {
		margin-top: 24px;
		
		font-size: 30px;
		text-align: center;
		color: #FFFFFF;
		text-shadow: 0 2px 3px rgba(0,0,0,0.22);
	}
}

.fold-container .body {
	display: block;
	max-width: 600px;
	padding: 0 14px;
	margin: 70px auto 70px auto;
	
	h2 {
		@extend .body-l;
		font-size: 34px;
		text-align: center;
		color: white;
		line-height: 1.4em;
		text-shadow: 0 2px 3px rgba(0,0,0,0.22);
	}
}

.fold-container a.producthunt {
	@include media(">medium") {
		position: absolute;
		bottom: 30px;
		left: calc(50% - 90px);
	}

	@include media("<=medium") {
		display: block;
		margin-top: 60px;
	}

	img {
		display: block;
		margin: 0 auto;
	}
}

.fold-container a.app-store {
	display: block;
	margin: 20px auto 0 auto;
	text-align: center;
	
	width: 157px;
	height: 40px;
	background-image: url('/assets/images/appstore-badge.png');
	background-size: 157px 40px;
	
	&:hover {
		opacity: 0.8;
	}
}

.fold-container .download-container {
  margin-top: 26vh;
}

.fold-container a.whats-new {
	display: inline-block;
  text-align: center;
  margin: 26px auto 0 auto;
  padding: 8px 10px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;

  transition: all 0.1s;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.fold-container span.app-store-notice {
	display: block;
	margin-top: 14px;
	font-weight: 500;
	font-size: 11px;
	color: rgba(255, 255, 255, 0.6);
	letter-spacing: -0.46px;
	text-align: center;
}

@include media("<=large") {
	.fold-container .body {
		max-width: 540px;
		margin-top: 46px;
		
		h2 {
			font-size: 26px;
		}
	}
}

@include media("<=medium") {
	.fold-container .app-icon {
		margin: 40px auto 0 auto;

		img {
		  width: 200px;
			height: 193px;
		}
		
		h1 {
			font-size: 40px;
		}
	}
	
	.fold-container .body {
		max-width: 500px;
		margin-top: 36px;
		
		h2 {
			font-size: 26px;
		}
	}
}

@include media("<=small") {
	.fold-container .app-icon {
		img {
		  width: 145px;
			height: 138px;
		}
		
		h1 {
			font-size: 34px;
		}
	}
	
	.fold-container .body {
		max-width: 500px;
		margin-top: 26px;
		
		h2 {
			font-size: 20px;
		}
	}
}
