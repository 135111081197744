/*
  Base
*/

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family:
    "SF Pro Text",
    "SF Pro Icons",
    -apple-system,
    "Helvetica Neue",
    "Helvetica",
    "Arial",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $body-background-color;

  &.plain {
    background: white;

    .footer-element-container {
      border-top: none;
    }
  }
}

p {
  margin-bottom: 1.7em;

  &:last-child {
    margin-bottom: 0;
  }
}

h5 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 7px;
}

ul,
ol {
  padding-left: 2em;
  margin-bottom: 18px;

  li {
    line-height: 1.5em;
  }
}

a {
  text-decoration: none;
  color: #007aff;
  font-weight: bold;

  &:hover {
    color: lighten(#007aff, 15%);
  }
}

/*
  Containers
*/

.body-container {
  min-height: calc(100vh - 70px);
}

.section-container {
  padding: 70px 0;

  &.black {
    background: black;
  }

  &.dark-gray {
    background: #111;
  }

  &.white {
    background: white;
  }
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

.break {
  display: block;
  width: 100%;
  height: 1px;

  margin: 50px auto;

  &.xsmall {
    margin: 15px auto;
  }

  &.small {
    margin: 25px auto;
  }

  &.larger {
    margin: 100px auto;
  }

  &.large {
    margin: 200px auto;
  }
}

/*
  Text colors
*/

.text-gradient {
  &.blue {
    background: linear-gradient(30deg, #3c7bad, #683d8a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.bright-blue {
    background: linear-gradient(30deg, #98d1ff, #d5a0ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.red {
    background: linear-gradient(30deg, #bf2a2a, #8a3d3d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.white {
    background: linear-gradient(30deg, #fff, #ccc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

/*
  Types
*/

.type-body-light {
  color: #fff;
}

.type-heading-light {
  color: #eee;
}

.body-xl {
  font-size: 56px;
  font-weight: 600;
  line-height: 1.3em;
  letter-spacing: -0.02em;

  @include media("<=medium") {
    font-size: 44px;
  }
}

.body-l {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.02em;
}

.body-mplus {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.001em;

  @include media("<=medium") {
    font-size: 22px;
  }
}

.body-m {
  font-size: 20px;
  line-height: 1.45;

  @include media("<=medium") {
    font-size: 18px;
  }
  letter-spacing: 0.001em;
}

.body-s {
  font-size: 16px;
  line-height: 1.45;
  letter-spacing: 0.001em;
}

/*
  Hero Container
*/

.hero-container {
  margin: 40px auto;
}

.hero-container .hero-heading {
  max-width: 580px;
  margin: 0 auto;
  text-align: center !important;
}

.hero-container .hero-body {
  text-align: center;
  max-width: 630px;
  margin: 40px auto 0;
  line-height: 1.48;
}

.hero-container .hero-accessory {
  display: block;
  margin: 44px auto 44px auto;
  max-width: 770px;
}

.hero-accessory.intro {
  max-width: 720px;
}

@include media("<medium") {
  .hero-container {
    margin: 24px;
  }
}

/*
  Misc
*/

.center {
  text-align: center;
}

/* Footer */

table.shortcuts {
  padding: 12px 24px;
  margin: 24px auto 50px auto;

  display: inline-block;
  width: auto;
  background: rgb(249, 249, 249);
  border-radius: 6px;
  border-collapse: collapse;

  th {
    text-align: left;
    padding-bottom: 24px;
  }

  tr {
    border-bottom: thin solid #eee;

    &:last-child {
      border-bottom: none;
    }

    td {
      padding: 12px 0;
      color: gray;

      &:nth-child(1) {
        padding-right: 40px;
      }

      &:nth-child(2) {
        font-weight: bold;
        color: black;
        text-align: right;
        padding-left: 50px;
      }
    }
  }
}

/*
  Scroll Animations
*/

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

// Add animation delays for multiple elements
.fade-in-sequence > * {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      transition-delay: #{$i * 0.1}s;
    }
  }

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}
