/*
	Home specific content
*/

.section-container.fold {
  min-height: 100vh;
}

.section-container.fold {
  .hero-fold-footer-container {
    display: block;
    width: 100%;
    color: white;
    position: absolute;
    bottom: 24px;

    .hero-fold-footer-content {
      max-width: 600px;
      display: block;
      margin: 0 auto;
    }
  }
}

.hero-fold {
  .hero-heading {
    position: relative;
    z-index: 999 !important;
    max-width: 700px;
  }

  .hero-accessory {
    max-width: 720px;
    margin-top: -50px;
    z-index: -1;
  }
}

.fold {
}

/// Assumption: used inside a card element.
.video-container {
  align-self: center;

  video {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.25);
  }

  @include media("<=medium") {
    //	margin: 30px auto;
    max-height: 50vh;

    video {
      display: block;
      margin: 0 auto;
      max-height: 50vh;
      border-radius: $card-border-radius-small - 8px;
    }
  }

  &.landscape {
    @include media("<=medium") {
      margin: 30px auto;
      width: 100%;
      max-height: unset;

      video {
        display: block;
        margin: 0 auto;
        max-height: unset;
        border-radius: 4px;
      }
    }
  }
}

.card-list-element {
  display: grid;
  grid-template-columns: 30% auto;
  column-gap: 100px;
  align-items: top;

  .card-list-element-title {
    place-self: top;
    justify-self: end;
    text-align: right;

    h5 {
      margin: 0;
    }
  }

  .card-list-element-body {
  }

  @include media("<large") {
    column-gap: 70px;
  }

  @include media("<medium") {
    grid-template-columns: 30% auto;
    column-gap: 30px;

    .card-list-element-title {
      h5.small {
        font-size: 20px;
      }
    }

    .card-list-element-body {
      p.small {
        font-size: 16px;
      }
    }
  }
}

.card-share-extension-footer {
  height: 400px;
  background-image: url("/assets/images/share-extension.jpg");
  background-position: top right;
  background-size: cover;
  border-bottom-right-radius: $card-border-radius;
  border-bottom-left-radius: $card-border-radius;

  @include media("<medium") {
    height: 280px;

    border-bottom-right-radius: $card-border-radius-small;
    border-bottom-left-radius: $card-border-radius-small;
  }
}

.row-copy-links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: $container-column-padding;

  @include media(">500px") {
    .card {
      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
      }
    }
  }

  @include media("<=500px") {
    display: block;

    .card {
      margin: 0 16px $container-column-padding 16px;
    }
  }
}

.card-quick-actions {
  background: #020f18;
  background-image: url("/assets/images/quick-actions-background-regular.jpg");
  background-size: 1225px 825px;
  background-position: 90% -50px;

  .content {
    max-width: 46%;

    h4 {
      color: #ffffff;
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.28);
    }

    p {
      color: white;
      opacity: 0.94;
      color: #ffffff;
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    }
  }

  @include media("<=medium") {
    background-position: right -50px;
    background-size: 170%;

    .content {
      max-width: 40%;
    }
  }

  @include media("<=600px") {
    background-image: url("/assets/images/quick-actions-background-mobile.jpg");
    background-size: cover;
    background-position: right top;

    .content {
      display: block;
      max-width: 100%;
      padding-top: 300px;
    }
  }
}

.card-dropover-cloud {
  background: #1767ff;
  color: white;
  align-self: stretch;

  .icon {
    img {
      width: 27px;
      height: 26px;
      margin-right: 6px;
    }

    span {
      line-height: 26px;
      font-size: 23px;
      letter-spacing: -0.82px;
      position: relative;
      top: -4px;

      &.emphasized {
        font-weight: 800;
        top: unset;
      }
    }
  }

  .tagline {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-weight: 800;
    font-size: 38px;
    color: #ffffff;
    letter-spacing: -0.04em;
    text-align: center;
  }

  a.explore {
    position: absolute;
    bottom: $container-column-padding;
    right: $container-column-padding;

    display: block;
    width: 45px;
    height: 45px;

    background-image: url("/assets/images/dropover-cloud-explore-button.png");
    background-size: 45px 45px;

    &:hover {
      opacity: 0.8;
    }
  }

  @include media("<=medium") {
    .tagline {
      font-size: 32px;
    }
  }

  @include media("<=500px") {
    min-height: 85vh;
    margin: 0 !important;
    border-radius: 0 !important;

    .tagline {
      //			font-size: 30px;
    }

    a.explore {
      bottom: 30px;
      right: 30px;

      width: 32px;
      height: 32px;

      background-image: url("/assets/images/dropover-cloud-explore-button.png");
      background-size: 32px 32px;
    }
  }
}

/*
	Menubar section
*/
.menubar-section-container {
  position: relative;
  min-height: 100vh;
  background: url("/assets/images/menubar-section-background.jpg");
  background-size: cover;
  position: top right;
}

.menubar-section-container .menubar-menu {
  display: block;
  position: absolute;
  top: 25px;
  right: 2px;
  width: 227px;
  height: 199px;

  background: url("/assets/images/menubar_menu.png");
  background-size: 227px 199px;

  @include media("<=phone") {
    visibility: hidden;
  }
}

.menubar-section-container .menubar {
  position: absolute;
  top: 0;

  display: block;
  height: 32px;
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
}

.menubar-section-container .menubar .menubar-item-container {
  padding: 0 20px;
  display: grid;
  height: 32px;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  justify-items: end;
  justify-content: end;
}

.menubar-section-container .menubar .menubar-item {
  height: 100%;

  &.time {
    color: white;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 32px;
  }

  &.dropover {
    margin-top: 2px;
    display: block;
    width: 50px;
    height: 28px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.15);

    background-image: url("/assets/images/menubar-icon.png");
    background-size: 30px 30px;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.menubar-section-container .lead {
  p {
    color: white;
  }
}

/*
	Features list
*/

.features-list-container ul {
  padding: 0 128px;
  column-count: 2;
  column-gap: $container-column-padding;

  @include media("<=large") {
    padding: 0 64px;
  }

  @include media("<=medium") {
    padding: 0 16px;
    column-count: 2;
  }
}

.features-list-container ul li {
  font-weight: 600;
  font-size: 20px;
  color: #3a3a3a;
  letter-spacing: -0.01em;
  line-height: 1.5em;

  list-style-type: none;
  margin-bottom: 16px;

  display: inline-block;

  mark {
    background: none;
    color: #453bd2;
    font-weight: 700;
  }

  @include media("<=medium") {
    font-size: 16px;
  }
}

/*
	Screenshots
*/
.screenshots-section-container {
  background: black;
}

.screenshots-container {
  padding: 0 64px;

  @include media("<=medium") {
    padding: 0 32px;
  }
}

.screenshots-container-row {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: $container-column-padding;
  margin-bottom: $container-column-padding;
  grid-column-gap: 6px;
  margin-bottom: 6px;

  &:nth-child(even) {
    left: -70px;
  }

  @include media("<=medium") {
    min-height: 140px;

    grid-column-gap: 8px;
    margin-bottom: 8px;

    &:nth-child(even) {
      left: -30px;
    }
  }

  @include media("<=small") {
    min-height: 50px;

    grid-column-gap: 8px;
    margin-bottom: 8px;

    &:nth-child(even) {
      left: -30px;
    }
  }
}

.screenshots-container .screenshots-container-item {
  position: relative;
  min-height: 330px;
  background: #333;
  overflow: hidden;
  padding: 0;
  margin: 0;

  background-position: center;
  background-size: cover;

  a.explore {
    display: block;

    position: absolute;
    bottom: 14px;
    right: 14px;

    width: 30px;
    height: 30px;

    background-image: url("/assets/images/dropover-cloud-explore-button.png");
    background-size: 30px;
    background-repeat: no-repeat;

    &.dark {
      background-image: url("/assets/images/dropover-cloud-explore-button-dark.png");
      background-size: 30px;
      background-repeat: no-repeat;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @include media("<=large") {
    min-height: 180px;
  }

  @include media("<=medium") {
    min-height: 140px;

    a.explore {
      bottom: 8px;
      right: 8px;

      width: 18px;
      height: 18px;

      background-image: url("/assets/images/dropover-cloud-explore-button.png");
      background-size: 18px;
      background-repeat: no-repeat;
    }
  }

  @include media("<=small") {
    min-height: 100px;

    a.explore {
      bottom: 8px;
      right: 8px;

      width: 18px;
      height: 18px;

      background-image: url("/assets/images/dropover-cloud-explore-button.png");
      background-size: 18px;
      background-repeat: no-repeat;
    }
  }
}

.download-section-container {
  position: relative;
  min-height: 100vh;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .download-container {
    text-align: center;

    img.app-icon {
      display: block;
      margin: 0 auto;
      width: 231px;
      height: 220px;
    }

    span.title {
      display: block;
      margin-top: 21px;

      font-size: 44px;
      font-weight: 800;
      color: #3d3e3f;
      letter-spacing: -1.57px;
      text-align: center;
    }

    a.app-store-badge {
      display: block;
      margin: 100px auto 0 auto;
      text-align: center;

      width: 157px;
      height: 40px;
      background-image: url("/assets/images/appstore-badge.png");
      background-size: 157px 40px;

      &:hover {
        opacity: 0.7;
      }
    }

    span.app-store-notice {
      display: block;
      margin-top: 14px;
      font-weight: 500;
      font-size: 11px;
      color: #6d6d6d;
      letter-spacing: -0.46px;
    }
  }
}

/*
	Cloud modal
*/

#dropover-cloud:target {
  display: block;
}

.cloud-modal {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;

  z-index: 99999; // Must be above navigation bar

  // background: $body-background-color;
  background: white;
}

.cloud-modal a.close {
  display: block;
  position: absolute;
  right: 32px;
  top: 32px;

  width: 55px;
  height: 55px;
  background-image: url("/assets/images/modal-close-button.png");
  background-size: 55px;

  @include media("<=phone") {
    width: 35px;
    height: 35px;
    background-image: url("/assets/images/modal-close-button.png");
    background-size: 35px;
  }
}

.cloud-modal-section-container {
  .container .lead h3 {
    color: #1959ff !important;
  }
}

.cloud-features-container {
  @include media("<=large") {
    padding: 0 32px;
  }
}

.cloud-features-item {
  display: grid;
  grid-template-columns: auto 30%;
  grid-template-areas: "image body";
  column-gap: 45px;
  align-items: top;

  &.reversed {
    grid-template-columns: 30% auto;
    grid-template-areas: "body image";
  }

  @include media("<=medium") {
    display: block;
  }
}

.cloud-features-item-separator {
  background-color: #d9d9d9;
  height: 1px;
  margin: 50px auto 50px auto;
}

.cloud-features-item-column {
  &.item-column-image {
    grid-area: image;

    img {
      max-width: 100%;
      border-radius: 18px;
    }
  }

  &.item-column-body {
    grid-area: body;

    h4 {
      font-weight: 700;
      font-size: 19px;
      color: #3a3a3a;
      letter-spacing: -0.01em;
      line-height: 1.5em;
      margin-bottom: 1.5em;
    }

    p {
      font-weight: 500;
      font-size: 17px;
      color: #3a3a3a;
      letter-spacing: -0.01em;
      line-height: 1.42em;
    }
  }

  @include media("<=medium") {
    &.item-column-image {
      img {
        margin-top: 24px;
        border-radius: 6px;
      }
    }

    &.item-column-body h4 {
      margin-bottom: 10px;
    }
  }
}

/*
	Ratings
*/
.ratings-section-container {
  background: white;
}

.ratings-container {
  display: block;
  width: 100%;

  margin: 100px auto;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 45px;
  row-gap: 45px;

  @include media("<=large") {
    padding: 0 24px;
  }

  @include media("<=medium") {
    grid-template-columns: 1fr 1fr;
    column-gap: 34px;
    row-gap: 34px;
  }

  @media (max-width: 440px) {
    grid-template-columns: 1fr;
    padding: 0 24px;
  }
}

.ratings-item {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 20px;

  border-radius: 12px;
  padding: 16px 24px;

  background: rgba(255, 255, 255, 1);
  background-image: url("/assets/images/ratings-icon.png");
  background-size: 31px 30px;
  background-position: calc(100% - 16px) 16px;
  background-repeat: no-repeat;

  @include media("<=phone") {
    display: none;

    // Only show first 6 items on mobile
    &:nth-child(-n + 6) {
      display: block;
    }
  }

  .ratings-item-body {
    p {
      max-width: calc(100% - 40px);
      font-weight: 500;
      font-size: 15px;
      color: #464646;
      letter-spacing: -0.02em;
      line-height: 1.3em;
    }
  }

  .ratings-item-footer {
    align-self: end;

    span {
      display: block;
      font-size: 12px;
      color: #6c6c6c;
      letter-spacing: -0.22px;
      text-align: right;
    }

    span.author {
      opacity: 0.9;
      font-weight: 500;
    }

    span.date {
      margin-top: 4px;
      opacity: 0.76;
    }
  }
}
