.faq-list-container {
  max-width: 720px;
  margin: 0 auto;

  @include media("<=medium") {
    /* padding: 24px; */
  }
}

.faq-list-element {
  display: block;
  width: 100%;
}

.faq-toggle {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid #eee;

  &:focus {
    outline: none;
  }

  h5 {
    margin: 0;
  }
}

button.faq-toggle {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;

  h5 {
    max-width: unset;
    margin: 0;
    padding: 14px 0;
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 1.4em;
    font-family:
      "SF Pro Text",
      "SF Pro Icons",
      -apple-system,
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    -webkit-font-smoothing: antialiased;

    transition: opacity 0.1s ease;

    &:hover {
      opacity: 0.6;
    }
  }
}

.faq-content {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition:
    height 0.4s ease,
    opacity 0.3s ease;

  p {
    margin-left: 0;
    padding-left: 0;
  }

  ul {
    padding-left: 20px;
    margin-left: 0;
  }
}

.faq-content.open {
  padding-top: 10px;
  opacity: 1;
  transition:
    height 0.4s ease,
    opacity 0.3s ease;

  &::after {
    content: "";
    display: block;
    margin-top: 10px;
  }
}
