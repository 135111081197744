nav {
  position: fixed;
  display: block;
  width: 100%;
  z-index: 9999;

  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(10px);

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  transition: all 1.5s;
}

nav .nav-element-container {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

nav .nav-element {
  padding: 12px 0;
}

nav .nav-element-header a {
  display: grid;
  grid-template-columns: 32px auto;
  column-gap: 12px;
  align-items: center;

  img {
    justify-self: start;
    display: block;
    width: 32px;
    height: 32px;
  }

  span {
    display: block;
    justify-self: start;
    font-weight: 500;
    font-size: 15px;
    color: #3d3e3f;
    letter-spacing: -0.005em;
  }
}

nav .nav-element-links {
  place-self: top;
  justify-self: end;
  text-align: right;

  ul {
    margin: 0;

    li {
      display: inline-block;
      margin-left: 24px;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        a {
          opacity: 0.5;
        }
      }

      a {
        padding: 6px 10px;
        border-radius: 6px;

        font-weight: 400;
        font-size: 13px;
        color: black;
        opacity: 0.9;
        letter-spacing: -0.007em;
        text-align: right;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

/*
	Appearance styles
*/

nav.minimal {
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(10px);

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .nav-element-container {
    .nav-element-header a {
      span {
        color: white;
        opacity: 0.8;
      }
    }
    .nav-element-links {
      ul li a {
        color: #eee;
      }
    }
  }
}

/*
	Responsive
*/
@include media("<=large") {
  nav .nav-element-container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@include media("<=medium") {
  nav .nav-element-container {
    .nav-element-links {
      display: block;
      width: 100%;

      ul {
        li {
          margin-left: 12px;

          a {
            font-size: 14px;
            padding: 2px 6px;
          }
        }
      }
    }
  }
}

// Mobil

nav .nav-element-header {
  @media (max-width: 540px) {
    padding: 0 !important;
    vertical-align: center;
    display: block;
    height: 70px;
  }
}

nav .nav-element-header a {
  @media (max-width: 540px) {
    height: 100%;

    span {
    }
  }
}

#nav-menu-toggle-button {
  display: block;
  background-image: url("/assets/images/mobile-nav-open.png");
  width: 40px;
  height: 70px;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  background-position: center;

  &.close {
    background-image: url("/assets/images/mobile-nav-close.png");
  }
}

nav.minimal #nav-menu-toggle-button {
  background-image: url("/assets/images/mobile-nav-open-white.png");

  &.close {
    background-image: url("/assets/images/mobile-nav-close-white.png");
  }
}

#main-nav {
  @media (max-width: 540px) {
    transition: all 0.3s;
    height: 70px;
  }

  &.menu-visible {
    height: 100vh;
  }
}

nav .nav-element-mobile-menu-toggle {
  display: none;
  position: absolute;
  right: 20px;

  @media (max-width: 540px) {
    z-index: 10;
    display: block; // Show only when viewport width is less than 500px
  }
}

nav .nav-element-links {
  @media (max-width: 540px) {
    transition:
      max-height 0.5s ease,
      opacity 0.5s ease;

    &.hidden {
      visibility: none;
      display: none;
      height: 0;
    }

    z-index: 5;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 80px 0 0 0;

    ul {
      padding: 0;
      margin: 0;

      li {
        display: block;
        text-align: center;
        padding: 0;
        margin: 0 !important;

        a {
          display: block;
          border-radius: 0;
          text-align: center;
          padding: 20px 20px 20px 30px !important;
          font-size: 18px !important;
          font-weight: 500;
        }
      }
    }
  }
}
