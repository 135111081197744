// Homepage fold
.section-container.fold {
  padding-top: 120px;
  min-height: 100vh;
}

.section-container.fold {
  .hero-fold-footer-container {
    display: block;
  }
}

.hero-fold {
  .hero-heading {
    position: relative;
    z-index: 999 !important;
    max-width: 700px;
  }

  .hero-body {
    h2 {
      font-weight: 600;
      font-size: 38px !important;
    }

  }

  .hero-accessory {
    max-width: 720px; 
    margin-top: -50px;
    z-index: -1;
  }
}

.fold-container-footer {
  margin-top: 130px;
  width: 100%;
  display: block;

  .content {
    max-width: 500px;
    margin: 0 auto;

    a {
      display: table;
      margin: 0 auto;
      clear: left;
      margin-bottom: 16px;

      &.emphasized {
        padding: 14px 20px;
        background: #0279FF;
        color: white;
        border-radius: 99px;
        text-align: center;
        font-size: 18px;
        letter-spacing: -0.3px;
        font-weight: 500;

        &:hover {
          background: #409AFF;
        }
      }

      &.link {
        color: #0279FF;
        padding: 14px 20px;
        border-radius: 99px;
        text-align: center;
        font-size: 16px;
        letter-spacing: -0.3px;
        font-weight: 500;


        svg.chevron {
          position: relative;
          top: 1px;
          margin-left: 2px;
          width: 8px;
          height: 14px;
          color: #0279FF;
        }

        &:hover {
          color: #409AFF;

          svg.chevron {
            color: #409AFF;
          }
        }
      }
    }
  }
}

@include media("<=medium") {
  .section-container-fold {
    padding-bottom: 20px;
  }
}

.definition-list-item {
	display: grid;
	grid-template-columns: 30% auto;
	column-gap: 30px;
	align-items: top;

	margin-bottom: 60px;

	&:last-child {
		margin-bottom: 0;
	}	

	@include media("<=phone") {
		display: block;
		margin-bottom: 30px;
	}
}

.definition-list-item-header {
	justify-self: end;

	h4 {
		font-weight: 700;
		font-size: 1.5em;
		color: #5E5E5E;
		letter-spacing: -0.01em;
		line-height: 1.5em;
		margin-top: 0;
		padding-top: 0;
	}
}

.definition-list-item-body {
	justify-self: start;
	margin-top: 8px;

	p {
		font-weight: 500;
		font-size: 1em;
		color: #5E5E5E;
		letter-spacing: -0.49px;
		line-height: 24px;
	}
}

.testflight-container {
  position: relative;
  max-width: 740px;
  width: 100%;
  min-height: calc(100vh - 140px);
  margin: 0 auto;

  .testflight-content-container {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    margin: 0 auto;
    width: 100%;
    padding: 0 30px;

    .lead h3 {
      color: #156CD0;
    }

    img {
      display: block;
      margin: 50px auto;
      width: 250px;
      height: 250px;
    }

    .button-container {
      margin: 100px auto;
      display: block;
      text-align: center;

      a {
        display: inline-block;
        margin: 0 auto;
        padding: 12px 18px;
        border-radius: 100px;
        background: #156CD0;

        color: white;
        font-size: 18px;
        font-weight: 700;

        &:hover {
          background: lighten(#156CD0, 10%);
        } 
      }

      span.notice {
        display: block;
        text-align: center;
        font-size: 11px;
        color: #333;
        margin-top: 15px;
      }
    }
  }
}

.diagnostics-container {
	display: block;
	position: relative;

	max-width: 700px;
	width: 100%;
	min-height: calc(100vh - 140px);
	margin: 0 auto;

	.diagnostics-content-container {
		position: absolute;
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);

		display: block;
		margin: 0 auto;
		width: 100%;
		padding: 0 30px;
		text-align: center;

		span.notice {
			text-align: center;
			font-weight: 600;
			font-size: 14px;
			font-style: normal;
			color: #ccc;
			letter-spacing: -0.49px;
			line-height: 1.7em;
		}	

		.diagnostics-content {
			display: block;
			margin: 6px auto;
			padding: 30px;
			border-radius: 8px;
			background: #F8F8F8;

			span {
				display: block;

				&.title {
					font-weight: 600;
					font-size: 16px;
					font-style: normal;
					color: #0088FF;
					letter-spacing: -0.49px;
					line-height: 1.7em;
				}

				&.value {
					font-size: .9rem;
					line-height: 1.66667;
					font-weight: 400;
					letter-spacing: -.027em;
					font-family: SF Mono,SFMono-Regular,ui-monospace,Menlo,monospace;
				}
			}
		}
	}
}


